class ConfigUtil {
  constructor() {
    this.config = {};
  }

  getConfig() {
    return this.config;
  }

  setConfig(config) {
    this.config = {
      ...config,
      AE_API_PROFILE_BASE: `${config.AE_API_HOST}/profiles/${config.AE_API_PROFILE_VERSION}`,
    };
    return this.config;
  }
}

export const configUtil = new ConfigUtil();
