import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Utilities } from '@advisors-excel-llc/react_ui_components';

import { configUtil } from './utilities/configUtil';

const AppLoading = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [AppComponent, setAppComponent] = React.useState(null);

  React.useEffect(() => {
    const response = Utilities.Api.request('GET', '/config.json', {});
    response.then((res) => {
      if (res.result) {
        configUtil.setConfig(res.result);
        import('./AppContainer').then((component) => {
          setAppComponent(component.default);
          setIsLoaded(true);
        });
      }
    });
  }, []);

  return (
    isLoaded ? AppComponent : null
  );
};

ReactDOM.render(
  <AppLoading />,
  document.getElementById('root'),
);
